export const PAYMENT_STATUSES = {
  PROCESSING: 'processing',
  DECLINED: 'declined',
  COMPLETED: 'completed',
};

export const PAYMENT_TYPES = {
  REFUND: 'refund',
  PAYMENT: 'payment',
};
