// Feature flag constants
export const FX_FEE_DISCOUNT_FLAGS = {
  FX_OUT: 'international_out_fee_discounts',
  FX_IN: 'international_in_fee_discounts',
} as const;

// FX Discount FAQ URL
// TODO: Add correct URL (waiting for product approval)
export const FX_DISCOUNT_FAQ_URL = 'https://qonto.com/';

// FX Discount Expected End Date
// TODO: Add correct date (waiting for product approval)
export const FX_DISCOUNT_EXPECTED_END_DATE = '2025-06-01';
